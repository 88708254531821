<template>
  <div class="footer">

    <div class="footer__socials">      

      <a href="https://github.com/dtumonasingh" target="_blank" class="footer-socials__github-icon"><img class="footer__icon" src="@/assets/fb.png" /></a>

       <a href="https://www.linkedin.com/in/mona-singh-582a7a89/" target="_blank" class="footer-socials__linkedin-icon"><img class="footer__icon" src="@/assets/Linkedin.png" /></a>

      <a href="https://stackoverflow.com/users/6268582/mona-singh" target="_blank" class="footer-socials__stackoverflow-icon"><img class="footer__icon" src="@/assets/insta.png" /></a>

     

      
    </div>

    <div class="footer__created-by">
      <p class="footer__message"> created by  <span class="footer__name">Mona Singh</span> </p>
    </div>

  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '../../styles/components/layout/_the-footer.scss';
</style>